export function formatPrice(
	priceInCents: number,
	options: Intl.NumberFormatOptions = {},
	locale = "fr-FR",
) {
	return new Intl.NumberFormat(locale, {
		style: "currency",
		...options,
		currency: options.currency || "EUR",
	}).format(priceInCents / 100);
}

export function formatPercent(
	value: number,
	options: Intl.NumberFormatOptions = {},
	locale = "fr-FR",
) {
	return new Intl.NumberFormat(locale, {
		style: "percent",
		maximumSignificantDigits: 2,
		...options,
	}).format(value / 100);
}
